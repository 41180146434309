































































import { Component, Vue } from 'vue-property-decorator'

import MasterTutorialsModule from '@/store/modules/master/tutorials'

@Component
export default class AppMiniTutotrial extends Vue {
  private get isTourStart() {
    return MasterTutorialsModule.miniTutorialIsActive
  }

  private get isTabletViewPort() {
    return this.$vuetify.breakpoint.width < 700
  }

  private steps = [
    {
      target: '#lesson-step',
      content: 'Чтобы найти уроки за предыдущие месяцы, тебе необходимо <b>переключить месяц</b> в окошке',
      header: {
        title: 'Уроки',
      },
      options: {
        labels: {
          buttonNext: 'Домашние работы',
        },
      },
      params: {
        enableScrolling: true,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 24],
            },
          },
        ],
        placement: this.isTabletViewPort ? undefined: 'right-start',
        number: 1,
      },
    },
    {
      target: '#exercise-step',
      content: 'В домашних работах ты также можешь смотреть задания за предыдущие месяцы. Для этого также <b>переключи месяц</b>  в окошке.',
      header: {
        title: 'Домашние работы',
      },
      options: {
        labels: {
          buttonPrevious: 'Уроки',
        },
      },
      params: {
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-16, 24],
            },
          },
        ],
        placement: this.isTabletViewPort ? undefined: 'right-start',
        number: 2,
      },
    },
  ]

  private normalizedSteps = this.steps

  // Позволяет использовать тур на нескольких страницах
  private getNormalizedSteps() {
    let totalSteps = this.steps
    const step1 = document.querySelector('#lesson-step')

    if(!step1) {
      totalSteps = totalSteps.slice(1)
    }

    this.normalizedSteps = totalSteps
  }

  private callbacks = {
    onFinish: this.tourDestroy,
    onNextStep: this.handleNextStep,
    onSkip: this.tourDestroy,
    onStart: this.tourStart,
    onStop: this.tourDestroy,
  }

  private handleNextStep(previousStep: number) {
    if(previousStep === 0) {
      this.$router.push({ name: 'master.exercises' })
    }
  }

  private handlePreviousStep() {
    const previousButton = document.querySelector('.tour-card__actions-btn_prev') as HTMLElement
    const previousStep = Number(previousButton.dataset.stepNumber) - 1
    if(previousStep === 1) {
      this.$router.push({ name: 'master.lessons' })
    }
  }

  private completeTour() {
    MasterTutorialsModule.setMiniTutorialActivity(false)
    this.$tours['app-mini-tutorial'].stop()
  }

  private tourStart() {
    MasterTutorialsModule.setMiniTutorialActivity(true)
  }

  // TODO - дописать
  private tourDestroy() {
    return
    //console.log('tourDestroy')
  }

  private mounted() {
    this.getNormalizedSteps()
    this.$tours['app-mini-tutorial'].start()
  }
}
