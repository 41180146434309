


























































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import AppMiniTutorial from '@/components/tutorials/AppMiniTutorial.vue'
import MasterMyExerciseCard from '@/components/cards/MasterMyExerciseCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MasterExercisesModule from '@/store/modules/master/exercises'
import { MasterEducationMasterGroupIdExercisesGetParams } from '@/store/types'
import MasterEducationModule from '@/store/modules/master/education'

@Component({
  components: {
    MasterMyExerciseCard,
    Select,
    AppMiniTutorial,
  },
})
export default class ExercisesList extends Mixins(MasterGroupMixin, NotifyMixin) {
  private get filter() {
    return MasterExercisesModule.exerciseContainersFilter
  }

  private set filter(filter: MasterEducationMasterGroupIdExercisesGetParams) {
    MasterExercisesModule.setExerciseContainersFilter(filter)
    this.fetchExercises()
  }

  private get exerciseContainers () {
    return MasterExercisesModule.exerciseContainers
  }

  private get taskTypes () {
    return DictionaryModule.taskTypes
  }

  private get previousMasterGroupID() {
    return MasterEducationModule.previousMasterGroupID
  }

  private mounted () {
    if (!this.filter.monthId || this.previousMasterGroupID !== this.currentMasterGroupID) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number, // MY_STEP: 3 Записываем из геттера
      }
      MasterEducationModule.setPreviousMasterGroupID(this.currentMasterGroupID)
    } else {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number, // MY_STEP: 3 Записываем из геттера
      }
    }
  }

  private fetchExercises () {
    if (this.currentMasterGroup) {
      MasterExercisesModule.fetchExerciseContainers({
        masterGroupID: this.currentMasterGroup.id,
        params: this.filter,
      })
        .catch(this.notifyError)
    }
  }

  private handleFilter(field: keyof MasterEducationMasterGroupIdExercisesGetParams, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }

    if (field === 'monthId') MasterEducationModule.setSavedMasterGroupMounthID(value)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Домашняя работа',
    }
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID () {
    MasterEducationModule.setSavedMasterGroupMounthID(null)
    MasterEducationModule.setPreviousMasterGroupID(this.currentMasterGroupID)
    if (this.currentMasterGroupMonth) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number,
      }
    }
  }
}
